.Main {
  height: 100%;
  // width: 100%;
  // overflow: scroll;
  // padding-top: 30px;
  // padding-bottom: 30px;
}

.lat-form {
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &::before {
    content: "";
    // width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    // background-color: #EDEDED;
    background-image: url("../../img/back-foto.jpg");
    background-size: no-repeat;
    background-position: center;
    background-size: 100%, cover;
    opacity: 0.2;
    z-index: -1;
  }

  &__container {
    // height: 100%;
    width: 100%;
    max-width: 375px;
    // min-height: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(30);
    position: relative;
    overflow: scroll;
    // opacity: 1.0;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: #EDEDED;
      background-image: url("../../img/back-1.jpg");
      background-size: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.7;
      z-index: -1;
    }



    &_shadow {
      box-shadow: 0 0 6px rgb(0 0 0 / 14%);
      border-radius: 12px;
    }


  }

  &__container-info {
    // height: 100%;
    width: 100%;
    // max-width: 375px;
    // min-height: 375px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: rem(30);
    position: relative;
    overflow: scroll;
    // opacity: 1.0;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      // background-color: #EDEDED;
      background-image: url("../../img/back-1.jpg");
      background-size: no-repeat;
      background-position: center;
      background-size: cover;
      opacity: 0.7;
      z-index: -1;
    }



    &_shadow {
      box-shadow: 0 0 6px rgb(0 0 0 / 14%);
      border-radius: 12px;
    }
  }

  &__box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__foo-btn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__sheets {
		width: 100%;
    // max-height: 600px;
		overflow: scroll;
	}

  &__office {
    overflow: scroll;
  }
}

.loging {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.logo {
  max-width: 150px;
  max-height: 150px;
}


.main-info {
  font-size: 18px;
  font-weight: bold;
  text-align: center;

  &__item {
    margin-bottom: 5px;
  }
}


.Lists {
	width: 100%;
  max-height: 400px;
	overflow: scroll;
}
